import { Formik } from 'formik';
import { INLINES } from '@contentful/rich-text-types';
import Link from 'next/link';
import { ComponentProps, FC } from 'react';

import { InputFragment } from '../formikInput/input.fragment';
import { SimpleRichTextType } from '../simpleRichText/simpleRichText.types';

import Heading from 'humanity/primitives/heading';
import Text from 'humanity/primitives/text';
import Button from 'humanity/primitives/button';
import Box from 'humanity/primitives/box';
import Loading from 'humanity/components/loading';
import SimpleRichText from 'humanity/components/simpleRichText';
import FormikInput from 'humanity/components/formikInput';
import Flex from 'humanity/primitives/flex';
import { buildDefaultValidationSchema } from 'utils/buildDefaultValidationSchema';
import { track } from 'utils/analytics';
import Disclosures from 'humanity/components/disclosures';

const getInitialValues = (inputs) =>
  inputs.reduce(
    (prev, curr) => ({
      ...prev,
      [curr?.fieldName]: '',
    }),
    {}
  );

type Props = {
  title?: string;
  subtitle?: string;
  disclosures?: SimpleRichTextType;
  submitButtonVariant?: 'primary' | 'secondary';
  submitButtonText?: string;
  formId: string;
  onSubmit: (values: unknown) => void; // eslint-disable-line no-unused-vars
  inputs: InputFragment[];
  initialValues?: Function | Record<string, unknown>;
  submitButtonWidth?: number | string | (number | string)[];
  submitButtonProps?: ComponentProps<typeof Button>;
  analyticsData?: ComponentProps<typeof FormikInput>['analyticsData'];
  isLoading?: boolean;
  variant?: 'dark' | 'light';
  bottomText?: string;
};

const ContentfulForm: FC<Props> = ({
  formId,
  inputs,
  onSubmit,
  bottomText,
  initialValues = getInitialValues,
  title = '',
  subtitle = '',
  disclosures = null,
  submitButtonText = 'Get started',
  submitButtonVariant = 'secondary',
  submitButtonProps = {},
  analyticsData = {},
  isLoading = false,
  variant = 'light',
  ...props
}) => {
  const defaultValues =
    typeof initialValues === 'function' ? initialValues(inputs) : initialValues;

  const validationSchema = buildDefaultValidationSchema(inputs);

  const fontColor = variant === 'light' ? 'blue100' : 'white';

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <>
          <Flex
            as="form"
            sx={{
              flexFlow: 'row wrap',
              justifyContent: 'space-between',
              mt: -2,
              width: '100%',
              textAlign: 'left',
            }}
            onSubmit={handleSubmit}
            data-testid="ContentfulForm"
            {...props}
          >
            {title && (
              <Heading
                as="h3"
                variant={['h4', null, 'h3']}
                width={1}
                mt={2}
                mb={3}
                color={fontColor}
              >
                {title}
              </Heading>
            )}
            {subtitle && (
              <Text variant="body" width={1} mt={-1} mb={3} color={fontColor}>
                {subtitle}
              </Text>
            )}
            {inputs.map((input) => {
              const mappedOptions = input?.optionsCollection?.items?.length
                ? [
                    // create an "empty" option to be placed at the front of the array, so that
                    // nothing is selected by default
                    {
                      disabled: true,
                      hidden: true,
                      label: '',
                      value: '',
                    },
                    // add the actual options to the end of our 1 item "empty" option array and
                    // map the contentful fields to an object our Select component will understand
                    ...input.optionsCollection.items.map((option) => ({
                      label: option.label,
                      value: option.value,
                    })),
                  ]
                : undefined;

              return (
                <FormikInput
                  key={input.sys?.id}
                  type={input?.type}
                  label={input?.label}
                  variant={variant}
                  required={input?.required}
                  name={input?.fieldName}
                  options={mappedOptions}
                  inputId={`${formId}-${input?.fieldName}`}
                  inputWidth={input?.width}
                  autocompleteValue={input?.autocompleteValue ?? undefined}
                  placeholder={input?.placeholder ?? undefined}
                  analyticsData={analyticsData}
                  defaultValue={input?.defaultValue}
                />
              );
            })}
            {disclosures && (
              <Disclosures disclosures={disclosures} fontColor={fontColor} mt={4} />
            )}
            {!isLoading && (
              <Button
                type="submit"
                variant={submitButtonVariant}
                btnSize="lg"
                width={1}
                disabled={isLoading}
                mt={4}
                data-testid={`${formId}-ContentfulFormSubmit`}
                {...submitButtonProps}
              >
                {submitButtonText}
              </Button>
            )}
            {isLoading && <Loading mx="auto" />}
          </Flex>
          {bottomText && (
            <Box mt={4}>
              <SimpleRichText
                content={bottomText}
                renderOptions={{
                  renderNode: {
                    [INLINES.HYPERLINK]: (node, children) => (
                      <Link
                        href={node.data?.uri}
                        onClick={() => track('form_secondary_cta')}
                      >
                        {children}
                      </Link>
                    ),
                  },
                }}
              />
            </Box>
          )}
        </>
      )}
    </Formik>
  );
};

export default ContentfulForm;
