import { inputFragment } from 'humanity/components/formikInput/input.fragment';

export const formFragment = (withImage = false) => `
  ... on Form {
    sys {
      id
    }
    title
    subtitle
    hubspotFormId
    hubspotSandboxFormId
    salesforceCampaignId
    formType
    disclosures {
      json
    }
    submitButtonText
    submitButtonVariant
    inputsCollection(limit: 10) {
      items {
        ${inputFragment({ withImage })}
      }
    }
  }
`;
